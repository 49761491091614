// Colors
$themes: (
    light: (
        body-bg-color: #ebe1d5,
        even-bg-color: #fdf8f2,
        odd-bg-color: #f9f3ed,
        txt-color: #625d52,
        title-color: #cf3033,
        date-color: #dd6b20,

        icon-color: #625d52,
        circle-color: #e2d9cf,
        circle-color-plain: #625d52,
    ),
    dark: (
        body-bg-color: #171717,
        even-bg-color: #242424,
        odd-bg-color: #625d52,
        txt-color: #d8d8d8,
        title-color: #fff,
        date-color: #c19d85,

        icon-color: #d8d8d8,
        circle-color: #6c6c6c,
        circle-color-plain: #eee,
    )
);

// Fonts
$body-font: 'Comfortaa', cursive;

$h1-font-size: 2.25rem; // 36px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.675rem; // 26px
$h4-font-size: 1.25rem; // 20px


// Helpers
$spacing-1: .4rem;
$spacing-2: .8rem;
$spacing-3: 1.2rem;
$spacing-4: 1.5rem;
$spacing-5: 2rem;