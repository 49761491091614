.skill-area {
	@extend .p-3;
	@extend .w-90;

	@include themify($themes) {
		background: themed('even-bg-color');
	}

	.wrapper-skills {
		display: grid;
		grid-template-columns: 1fr;

		/*@media screen and (min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
            row-gap: 1rem;
            column-gap: 1rem;
        }*/

		@media screen and (min-width: 1080px) {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 3rem;
			margin: auto;
			max-width: 1200px;
		}

		.skills {
			margin: $spacing-3 0;

			h3 {
				display: flex;
				margin-bottom: $spacing-3;
			}

			p {
				line-height: 1.5;
			}

			.learning {
				@include themify($themes) {
					color: themed('date-color');
				}

				@extend .txt-small;
			}

			.skill {
				@include themify($themes) {
					background: themed('circle-color');
					color: themed('circle-color');
				}

				border-radius: 15px;
				font-weight: 500;
				margin-bottom: $spacing-4;

				&::before {
					@include themify($themes) {
						background: themed('circle-color-plain');
					}

					border-radius: inherit;
					content: attr(data-skill);
					font-size: 0.9rem;
					display: inline-block;
					padding: $spacing-1 0 $spacing-1 $spacing-2;

					animation: load 2s 0s;
					-webkit-animation: load 2s 0s;
				}

				&.learning::before {
					width: 20%;
				}
				&.basic::before {
					width: 40%;
				}
				&.intermediate::before {
					width: 60%;
				}
				&.advanced::before {
					width: 80%;
				}
				&.expert::before {
					width: 100%;
				}
			}
		}
	}
}

@keyframes load {
	from {
		width: 0%;
	}
}
@-webkit-keyframes load {
	from {
		width: 0%;
	}
}
