.scrollTop {
    @include themify($themes) {
        color: themed('date-color');  
    }

    //animation: fadeIn 0.3s;
    bottom: 20px;
    cursor: pointer;
    display: inline-block;
    font-size: $h2-font-size;
    opacity: 0;
    position: fixed; 
    right: 20px;    
    transition: opacity 0.4s;  
    z-index: 1000;

    &.show{
        display: block;
        opacity: 0.5;
        transition: opacity 0.4s;

        &:hover{
            opacity: 1;
        }
    }
      
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }