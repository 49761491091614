.cta{
    @include themify($themes) {
        background-color: themed('body-bg-color');  
    }

    @extend .p-3;
    @extend .w-90;
    align-items: center;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    column-gap: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50vh;
    position: relative;
    text-align: center;

    &:before{
        @include themify($themes) {
            background: themed('body-bg-color');  
        }

        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: .8;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    > *{
        position: relative;
    }

    @media screen and (min-width: 768px){
        background-attachment: fixed;
        text-align: left;
    }

    button{
       
        position:relative;
        transition:0.5s all ease;

        &:before,
        &:after{
            @include themify($themes) {
                background: themed('circle-color-plain');  
            }
            content: "";
            height: 40px; 
            position:absolute;       
            transition:0.5s all ease;
            width:40px;
          }

        &:before{
            left: 0;
            top: 0;     
            
        }

        &:after{
            right: 0;
            bottom: 0; 
            z-index: 0;
        }

        &:hover{
            span{
                @include themify($themes) {
                    background: themed('title-color');  
                    color: themed('circle-color');   
                }
    
                transition:0.5s all ease;
            }

            &:before{
                transform: translate(-5px, -5px);
            }

            &:after{
                transform: translate(5px, 5px);
            }
        }

        span{
            @include themify($themes) {
                background: themed('circle-color');  
                color: themed('circle-color-plain');  
            }
            
            display: block;
            font-size: $h4-font-size;
            font-weight: 700;
            text-transform: uppercase;
            padding: $spacing-3;
            position: relative;
            z-index: 1;
        }

    }
}