// Margin
.mb-3{
    margin-bottom: $spacing-3;
}

.mb-5{
    margin-bottom: $spacing-5;
}

// Padding
.p-3{
    padding: $spacing-3;
}

.w-90{
    padding-left: 5%;
    padding-right: 5%;
}