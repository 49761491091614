.interests {
	@extend .p-3;
	@extend .w-90;

	@include themify($themes) {
		background: themed('even-bg-color');
	}

	min-height: 50vh;

	align-items: center;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;

	&:before {
		@include themify($themes) {
			background: themed('body-bg-color');
		}

		content: '';
		display: block;
		height: 100%;
		left: 0;
		opacity: 0.8;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;
	}

	> * {
		position: relative;
	}

	@media screen and (min-width: 768px) {
		background-attachment: fixed;
		text-align: left;
	}

	.slick-list {
		margin-bottom: $spacing-5;
	}

	.slick-dots {
		align-items: center;
		display: flex !important;
		justify-content: center;

		li {
			align-items: center;
			display: flex;
			justify-content: center;
		}

		button {
			@include themify($themes) {
				background: themed('circle-color-plain');
			}

			border-radius: 50%;
			height: 6px;
			padding: 0;

			width: 6px;

			&:before {
				content: '';
			}
		}

		.slick-active {
			button {
				@include themify($themes) {
					background: themed('circle-color-plain');
				}
				align-items: center;
				display: flex;
				justify-content: center;
				height: 10px;
				width: 10px;

				&:before {
					@include themify($themes) {
						background: themed('even-bg-color');
					}
					border-radius: 50%;
					height: 6px;
					opacity: 1;
					position: inherit;
					width: 6px;
				}
			}
		}
	}

	.card {
		@include themify($themes) {
			background: rgba(themed('circle-color'), 0.6);
		}

		align-items: center;
		display: grid !important;
		grid-template-columns: 300px 1fr;
		grid-template-areas:
			'visuel title'
			'visuel description'
			'visuel links';
		margin: 0 auto;
		max-width: 70%;
		padding: $spacing-2;

		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
			grid-template-areas:
				'visuel'
				'title'
				'description'
				'links';
		}

		@media screen and (max-width: 1279px) {
			max-width: 90%;
		}

		.card__title {
			@include themify($themes) {
				color: themed('date-color');
			}

			grid-area: title;

			@media screen and (max-width: 767px) {
				text-align: center;
			}
		}

		.card__visuel {
			@include themify($themes) {
				border: 2px solid themed('circle-color-plain');
			}

			grid-area: visuel;

			@media screen and (max-width: 767px) {
				margin: 0 auto $spacing-2;
			}
		}

		.card__text {
			grid-area: description;
		}

		.card__links {
			grid-area: links;
		}
	}
}
