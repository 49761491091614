.contact{
    @extend .p-3;
    @extend .w-90;

    @include themify($themes) {
        background: themed('odd-bg-color');  
    }

    .contact__form{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 500px;
        padding: $spacing-2;

        label{
            font-weight: 700;
        }

        input,
        textarea, 
        button{
            margin: $spacing-1 0 $spacing-3;
            padding: $spacing-1;
        }

        input,
        textarea{
            @include themify($themes) {
                color: themed('txt-color');  
            }

            appearance: none;
            background: transparent;
            border: none;
            font-family: $body-font;

            &::placeholder{
                @extend .txt-small;

                @include themify($themes) {
                    color: themed('txt-color');  
                }

                font-family: $body-font;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        input{
            @include themify($themes) {
                border-color: themed('txt-color');  
            }

            border-bottom: 1px solid;
        }

        textarea{
            @include themify($themes) {
                border: 1px solid themed('txt-color');  
            }

            height: 150px;
        }

        button{
            @include themify($themes) {
                background: themed('circle-color-plain');  
                color: themed('circle-color'); 
            }

            border-radius: 15px;
            font-size: $h4-font-size;
            font-weight: bold;
            margin: 0 auto;
            transition:0.5s all ease-in-out;
            width: 50%;


            &:hover{
                @include themify($themes) {
                    background: themed('circle-color'); 
                    color: themed('circle-color-plain');      
                }
                transition:0.5s all ease-in-out;
            }
        }
    }
}