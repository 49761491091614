@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~aos/dist/aos.css';

*,
::before,
::after {
	box-sizing: border-box;
	hyphens: auto;
	outline: 0;
	margin: 0;
	padding: 0;
}

body {
	overflow-x: hidden;
	position: relative;
}

@import 'setup/variables';
@import 'setup/mixin';
@import 'setup/fonts';
@import 'setup/helpers';

// Grids
.grid__row {
	margin: 0 0 $spacing-4;
}

@media screen and (min-width: 768px) {
	.grid__container {
		display: grid;
		grid-template-columns: 1fr 3fr;
	}

	.grid__row {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
}

// DarkMode
#darkMode {
	left: $spacing-3;
	position: fixed;
	top: $spacing-3;
	z-index: 11;
}

// Desable animation
/* Small Devices, Tablets */
@media only screen and (max-width: 1023px) {
	[data-aos] {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;

		opacity: 1 !important;
	}
}

@import '../components/ScrollProgressBar/ScrollProgressBar';
@import '../components/Navigation/nav';
@import '../components/User/user';
@import '../components/Skills/skills';
@import '../components/FormationsExperiences/FormationsExperiences';
@import '../components/Interests/Interests';
@import '../components/Cta/cta';
@import '../components/Contact/contact';
@import '../components/Footer/footer';
@import '../components/Navigation/backToTop';

@import './print';
