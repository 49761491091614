.footer{
    @extend .txt-small;
    
    margin: 0;
    padding: $spacing-2;
    text-align: center;

    p{
        margin: 0;
    }
}