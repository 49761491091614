.user{
    @include themify($themes) {
        background-color: themed('body-bg-color');  
    }

    @extend .p-3;
    @extend .w-90;

    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    
    grid-template-areas: 
    "logo username"
    "logo profession"
    "infos profil";
    column-gap: 4rem;
    position: relative;
    text-align: center;

    > *{
        position: relative;
    }

    @media screen and (min-width: 768px){
        background-attachment: fixed;
        text-align: left;
    }

    &:before{
        @include themify($themes) {
            background: themed('body-bg-color');  
        }

        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: .9;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
    }

    .user__avatar{
        @include themify($themes) {
            background: themed('body-bg-color');  
            box-shadow: 2px 7px 0 themed('date-color'), -2px -7px 0 themed('date-color');
        }
    
        border-radius: 50%; 
        grid-area: logo;
        margin: 0 auto $spacing-4;
        max-width: 150px;
        padding: $spacing-2;
        position: relative;

        img{
            display: block;
            width: 100%;
        }
    }

    .user__name{
        align-self: center;
        grid-area: username;
    }

    .user__profession{
        grid-area: profession;
        font-size: $h3-font-size;

        @media screen and (min-width: 768px){
            font-size: $h2-font-size;
        }
    }

    .user__infos{
        grid-area: infos;
        padding: 0 0 $spacing-1;
        text-align: center;

        @media screen and (min-width: 768px){
            text-align: left;
        }

        address{
            font-style: normal;
            margin-bottom: 0.8rem;
        }

        .user__info{
            display: flex;
            justify-content: center;

            @media screen and (min-width: 768px){
                justify-content: flex-start;
            }

            svg{
                @include themify($themes) {
                    color: themed('icon-color');  
                }
                
                flex: 0 0 1rem;
                margin-right: $spacing-2;
            }

            a{
                cursor: pointer;
            }
        }
    }

    .user__profil{
        grid-area: profil;
        margin: $spacing-3 0;
        text-align: left;

        @media screen and (min-width: 768px){
            margin: 0;
        }
    }

}