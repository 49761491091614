.navbar{
    @include themify($themes) {
        background: themed('circle-color');  
        color: themed('circle-color-plain');
        box-shadow: 0 1px 4px -2px rgba(themed('circle-color-plain'), .8);
    }

    display: grid;
    grid-template-columns: 1fr 40px;
    min-height: 60px;
    left: 0;
    padding: $spacing-2 $spacing-2 $spacing-2 $spacing-3*4;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;

    .navbar__links{
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: 500;
        list-style: none;
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        .navbar__item{
            margin-bottom: 0;

            .navbar__link{
                cursor: pointer;
                display: block;
                padding: 0 $spacing-1;
                position:relative;
                transition:0.5s color ease;
                
                &:after{
                    @include themify($themes) {
                        background: themed('circle-color-plain');  
                    }
                    bottom: -0.25em;
                    content: '';
                    display: block;
                    height: 1px;
                    left:50%;
                    position: absolute;
                    transform:translateX(-50%);
                    transition: 0.5s all ease;
                    width:0;
                }

                &:hover{
                    @include themify($themes) {
                        color: themed('date-color');  
                    }

                    &:after{
                        @include themify($themes) {
                            background: themed('date-color');  
                        }
                        width:70%;
                    }
                }
                
                &.active-link:not(.not-active-link){
                    @include themify($themes) {
                        color: themed('date-color');  
                    }
                    pointer-events: none;
                    &:after{
                        @include themify($themes) {
                            background: themed('date-color');  
                        }
                        width:70%;
                    }
                }
            }
        }        

        @media screen and (max-width: 1023px) {
            @include themify($themes) {
                background: themed('circle-color');  
                color: themed('circle-color-plain');
            }

            align-items: center;
            bottom: 0;
            flex-direction: column;
            height: 100vh;
            justify-content: center;
            padding: $spacing-3;
            position: fixed;
            right: -100vw;
            transition: all .8s ease-out;
            visibility: hidden;
            width: 0;

            .navbar__item{
                transform: translateY(100vh);
                transition-property: all;
                transition-timing-function: ease-out;

                &:after{
                    @include themify($themes) {
                        background: themed('circle-color-plain');
                    }

                    content: '';
                    display: block;
                    height: 1px;
                    margin: 2rem auto;
                    width: 3vw;
                }

                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }

            &.show-nav{
                right: 0;
                visibility: visible;
                width: 100vw;

                .navbar__item{
                    transform: translateY(0);
                    transition-property: all;
                    transition-timing-function: ease-out;
                }   
            }

            .navbar__link{
                display: block;
                font-size: 5vw;
                padding: $spacing-3;
                transition: all .8s ease-out;

                &:hover{
                    transition: all .8s ease-out;
                }
            }
        }
    }

    .navbar__burger{
        background: transparent;
        border: none;
        color: inherit;
        height: 40px;
        width: 40px;

        @media screen and (max-width: 1023px){
            display: block;
            position: fixed;
            right: 1rem;
            top: .8rem;

            &:hover{
                cursor: pointer;
            }

            .burger-line,
            .burger-line:before,
            .burger-line:after{
                @include themify($themes) {
                    background: themed('circle-color-plain');
                }

                border-radius: 3px;
                display: block;
                height: 3px;
                position: relative;
                transition: all .5s ease-in-out;
                width: 40px;                
            }

            .burger-line:before,
            .burger-line:after{
                content: '';
                left: 0;
                position: absolute;
            }

            .burger-line:before{
                transform: translateY(-12px);
            }

            .burger-line:after{
                transform: translateY(12px);
            }

            &.show-nav{
                .burger-line{
                    background: transparent;
                    width: 0;

                    &:before{
                        transform: rotate(45deg);
                    }

                    &:after{
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    
}