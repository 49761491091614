// General
html{
    font-size: 16px;
    line-height: 1.2;
}

body{
    font-family: $body-font;
    font-weight: 300;
}

#wrapper{
    @include themify($themes) {
        background: themed('body-bg-color');  
        color: themed('txt-color');  
    }
}

// Titres
h1,
h2,
h3{
    @include themify($themes) {
        color: themed('title-color');  
    }

    font-weight: 700;
    margin-bottom: $spacing-1;

    svg{
        margin-right: $spacing-2;

        path{
            @include themify($themes) {
                stroke: themed('title-color');  
            }
        }
    }
}

h1{
    font-size: $h1-font-size;
    text-transform: uppercase;
}

h2{
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: $h2-font-size;
    justify-content: center;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;

    &:before,
    &:after{
        @include themify($themes) {
            background: themed('title-color');  
        }

        content: '';
        display: block;
        height: 2px;
        margin: $spacing-2 0;
        width: 50%;
    }

    & + p{
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: $spacing-5*1.5;
        text-align: center;
    }
}

h3{
    font-size: $h3-font-size;
}

// Textes
p{
    margin-bottom: $spacing-2;
}

.txt-small{
    font-size: .8rem;
}

// Listes
ul{
    padding-left:  $spacing-3;

    li{
        margin-bottom: $spacing-2;
    }
}

// Liens
a{
    @include themify($themes) {
        color: themed('txt-color');  
    }
    
    display: inline-block;
    position:relative;
    text-decoration: none;
    transition:0.5s color ease;

    &:after{
        @include themify($themes) {
            background: themed('circle-color-plain');  
        }
        bottom: -0.25em;
        content: '';
        display: block;
        height: 1px;
        left:50%;
        position: absolute;
        transform:translateX(-50%);
        transition: 0.5s all ease;
        width:0;
    }

    &:hover{
        @include themify($themes) {
            color: themed('date-color');  
        }

        &:after{
            @include themify($themes) {
                background: themed('date-color');  
            }
            width:70%;
        }
    }
}

// Btn
button{
    @include themify($themes) {
        color: themed('txt-color') ;  
    }

    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    svg{
        font-size: 1.2rem;
    }
  
}