// Generate pdf
#cv-print{
    width: 100% !important;
}

.cv-print{
    ul {
        margin:0;
        padding:0;
        
        li {
            list-style-type:none;

            &::before {
                content: "•";
                display: inline-block; 
                font-size: 110%;
                padding-right: 10px;
            }
        }
    }
      
    .actions{
        display: none;
    }

    .grid__container {
        font-size:12px !important;
        max-width: 210mm;
    }

    .sidebar {
        height: 297mm;
    }

    .user__infos svg {
        display: none !important;
    }
    
    .circle {
        width:10px;
        height:10px;
    }

    .grid__date {
        margin-top:5px;
    }
}