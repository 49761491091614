.cursus{
    @extend .p-3;
    @extend .w-90;
    
    @include themify($themes) {
        background: themed('odd-bg-color');  
    }

    & + .cursus{
        @include themify($themes) {
            background: themed('even-bg-color');  
        }
    }

    .grid__row{
        display: grid;
        grid-template-areas: "timeline infos";
        grid-template-columns: 2px 1fr;
        grid-column-gap: 1rem;
        margin-bottom: 0;

        @media screen and (min-width: 768px){
            grid-column-gap: 3rem;
        }


        @media screen and (min-width: 768px){
            grid-template-columns: 1fr 2px 1fr;

            &:nth-child(even){
                grid-template-areas: "empty timeline infos";
            }
    
            &:nth-child(odd){
                grid-template-areas: "infos timeline empty";
    
                text-align: right;
    
                .cursus__viewmore,
                .mission__viewmore{
                    justify-content: flex-end;
                }
    
                .cursus__detail,
                .mission__detail{
                    margin-left: auto !important;
                    margin-right: 0 !important;
                    text-align: left;
                }
    
                .tags{
                    justify-content: flex-end;
                    margin: 0 0 0 auto !important;
                }
            }
        }

        .grid__item{
            padding-bottom: $spacing-5;

            @media screen and (min-width: 768px){
                padding-bottom: $spacing-1;
            }

            grid-area: infos;

            &.grid_timeline{
                grid-area: timeline;
                position: relative;

                @include themify($themes) {
                    background: themed('title-color');  
                }

                &:after{
                    @include themify($themes) {
                        background: themed('date-color');  
                        border-color: themed('title-color');  
                    }

                    border: 4px solid;
                    border-radius: 50%;
                    content: '';
                    display: block;
                    height: 18px;
                    position: absolute;
                    transform: translate(calc(-50% + 1px), 2rem);
                    width: 18px;
                }
            }
        
            .grid__date{
                @include themify($themes) {
                    color: themed('date-color');  
                }

                @extend .txt-small;
                font-weight: 500;
            }
        
            .grid__title{
                font-size: $h3-font-size;
                margin-bottom: $spacing-2;
            }
        
            .grid__location{
                @include themify($themes) {
                    color: themed('date-color');  
                }
                font-weight: 500;
            }
        
            .grid__missions{
                font-size: .8rem;
                list-style: none;
                padding: 0;
                
                .grid__mission{
                    margin-bottom: $spacing-4;
        
                    .tags{
                        align-items: stretch;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;
                        padding: 0;

                        @media screen and (min-width: 768px){
                            width: 70%;
                        }
        
                        .tag{
                            @include themify($themes) {
                                background: themed('circle-color-plain');  
                                color: themed('circle-color');  
                            }
                            border-radius: 20px;
                            font-weight: 500;
                            list-style: none;
                            margin: $spacing-1;
                            padding: $spacing-1 $spacing-2;
                        }
                    }
                }
            }
        
            .cursus__viewmore,
            .cursus__detail{
                font-size: .8rem;
            }
        
            .cursus__viewmore,
            .mission__viewmore{
                align-items: center;
                cursor: pointer;
                display: flex;
        
                svg{
                    font-size: 1.2rem;
                }
            }
        
            .cursus__detail,
            .mission__detail{
                @include themify($themes) {
                    border: 2px solid themed('date-color');  
                }
                display: block;
                margin: $spacing-2 0;
                padding: $spacing-2;

                @media screen and (min-width: 768px){
                    width: 70%;
                }
            }

            .cursus__detail{
                @include themify($themes) {
                    background: themed('even-bg-color');    
                }
            }

            .mission__detail{
                @include themify($themes) {
                    background: themed('odd-bg-color');    
                }
            }
        }
        
    }
}